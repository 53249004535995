import React from 'react'

import {
  Layout,
  SEO,
  CaseStudy,
  Showcase,
  Carousel,
  Metrics,
} from '../components'

import excelPreview from '../images/excel.svg'
import driverAppPreview from '../assets/lottie/android_driver_app.json'
import managerAppPreview from '../images/android_manager_app.png'
import iconDatabase from '../images/database.svg'
import iconWorkerKPI from '../images/worker_kpi.svg'
import iconYardKPI from '../images/yard_kpi.svg'
import socialCard from '../images/social_card_intrmodl.png'

function getCarouselImages() {
  const requireContext = require.context(
    '../images/carousel_intrmodl',
    true,
    /\.(png|jpe?g|svg)$/
  )
  const requireContextKeys = requireContext.keys()
  return requireContextKeys.map((key) => requireContext(key))
}

const carouselImages = getCarouselImages()

const resultData = [
  { title: 'Sensor data every', value: '2s', backgroundIcon: iconDatabase },
  { title: 'Worker KPI’s every', value: '1hr', backgroundIcon: iconWorkerKPI },
  { title: 'yard KPI’s every ', value: '3hrs', backgroundIcon: iconYardKPI },
]

const Intrmodl = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Intrmodl"
      image={socialCard}
      description="An operation management tool that uses IoT and digital reporting to generate a data-driven view of intermodal operations."
    />
    <CaseStudy
      projectName="Intrmodl"
      descriptionTitle="The Problem"
      description="RMS Intermodal is America’s 2nd largest intermodal terminal operator, moving ten's of thousands of shipping containers everyday. They have several challenges related to  their processes."
      previewImage={excelPreview}
      showcase
    />
    <Showcase
      title="Android Driver App"
      description="The driver app provides tracking data for movement and shipping container placement that managers can then view."
      lottieAnimation={driverAppPreview}
      direction="normal"
    />
    <Showcase
      title="Android Manager app"
      description="Using tablets, managers can be on the go, submiting audits and keeping track of their yard."
      image={managerAppPreview}
      direction="inverted"
    />
    <Carousel
      title="Webapp"
      description="Managers can see their yard’s movements in real time, see KPI’s for each worker, quickly search for a vehicle status, send notes, monitor reports, and view their yard’s KPI’s."
      images={carouselImages}
    />
    <Metrics
      title="The Result"
      description="Managing yards has never been so straightforward. In the next couple of years, GNAR will continue to bring RMS meaningful results."
      data={resultData}
    />
  </Layout>
)

export default Intrmodl
